import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";

import Layout from "../../layouts/default";

export const Head = () => (
  <title>Chinese Comprehensible Input related to food | MandarInput</title>
);

export const FoodPage = ({ data }: PageProps<Queries.FoodVideosQuery>) => {
  const filteredVideos = data.allVideosYaml.edges.map((node) =>
    videoFromNode(node.node)
  );

  return (
    <Layout>
      <Row>
        <Col sm={12} lg={4}>
          <div className="card">
            <h2>Learn Mandarin with food videos</h2>
            <p>
              If you&apos;re learning Mandarin it&apos;s useful to listen to
              videos on topics that you are interested in, and suit your
              purposes for learning.
            </p>
            <p>
              These videos all relate to food and drink, particularly in Chinese
              culture. We have <Link to="/">lots of other videos</Link> if
              you&apos;re interested in other topics.
            </p>
            <p>
              Chinese food is one of the oldest and most beloved cuisines in the
              world. It has a rich history, with a variety of regional styles,
              ingredients, and flavors that have been perfected over centuries.
              From the traditional flavors of Cantonese cooking to the bold
              spices of Szechuan cuisine, Chinese food is sure to please any
              palate. In this list of videos, we&apos;ll explore the unique
              flavors, ingredients, and recipes of Chinese food. Whether
              you&apos;re a passionate home cook or a curious foodie,
              you&apos;re sure to learn something new about this delightful
              cuisine. So grab your chopsticks, and let&apos;s dive into the
              amazing flavors of Chinese food!
            </p>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          {filteredVideos.map(
            (video) =>
              video !== null && (
                <SearchResult
                  key={video.videoId}
                  video={video}
                  viewed={false}
                />
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default FoodPage;

export const pageQuery = graphql`
  query FoodVideos {
    allVideosYaml(filter: { topics: { in: "food" } }) {
      edges {
        node {
          slug
          hsk
          duration
          channel
          topics
          title
          videoId
          subtitles {
            chinese
            english
            pinyin
          }
          speechSpeed
          difficulty
        }
      }
    }
  }
`;
